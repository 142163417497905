<template>
    <CRow>
        <CModal
                title="Email User"
                :show.sync="emailModal"
                @update:show="closeModal"
                closeOnBackdrop
                size="xl"
                centered
        >

            <CForm v-if="selected">
                <CInput
                        label="User Email"
                        :value.sync="selected.usr_email"
                />
                <CInput
                        label="Email Subject"
                        :value.sync="email.subject"

                />


                <wysiwyg label="Email Content" v-model="email.content"/>


            </CForm>

        </CModal>
        <CCol sm="4">
            <CCard>
                <CCardHeader>
                    <strong> User Search </strong> <small></small>
                    <div class="card-header-actions"></div>
                </CCardHeader>
                <CCardHeader>
                    <CInput v-c-tooltip.hover.click="`Start typing anything that might describe one user.`"
                            @input="userLookup()" v-model="search_criteria" placeholder="Search">
                        <template #prepend>
                            <CButton @click="userLookup()" size="sm" color="primary">
                                <CIcon name="cil-magnifying-glass"/>
                                Search
                            </CButton>
                        </template>
                    </CInput>
                </CCardHeader>
                <div v-for="user in founded_users">

                    <h6 style="margin-left: 20px; margin-top: 5px; text-underline-position: below"
                        @click="selectUser(user)"
                    >
                        📧 {{user.usr_email}} <br><br> 👤 {{user.usr_firstname}} {{user.usr_lastname}} <br><br> 📶
                        {{user.wlan_ssid}}_{{user.radio}}
                    </h6>
                    <hr>
                </div>


                <!--                </CListGroupItem>-->
            </CCard>
        </CCol>
        <CCol sm="8">
            <CCard>
                <CCardHeader>
                    Details
                    <div v-if="apsync" class="lds-ripple">
                        <div></div>
                        <div></div>
                    </div>
                    <CBadge v-if="!apsync && ap_data" type="submit" size="sm" @click="apDetails(selected)"
                            color="info">
                        🔄
                    </CBadge>
                </CCardHeader>
                <CCardBody>
                    <CTabs variant="pills">

                        <CTab title="User Info" v-if="selected">
                            <CCard>
                                <CCardHeader>
                                    Edit User Info
                                </CCardHeader>
                                <CCardBody>
                                    <CForm>
                                        <CInput
                                                description="Customer's email"
                                                label="Email address"
                                                horizontal
                                                id="email_address"
                                                type="email"
                                                :value="selected.usr_email"
                                                autocomplete="name"
                                                placeholder="Email address"

                                        />

                                        <CInput
                                                description="Customer's phone"
                                                label="Phone number"
                                                horizontal
                                                id="phone_number"
                                                :value="selected.usr_phonenumber"
                                                autocomplete="name"
                                                type="tel"
                                                placeholder="Phone Number"

                                        />

                                        <CInput
                                                description="First Name"
                                                label="First Name" f
                                                id="first_name"
                                                horizontal
                                                :value="selected.usr_firstname"
                                                autocomplete="name"
                                                placeholder="Text"

                                        />

                                        <CInput
                                                description="Last Name"
                                                label="Last name"
                                                id="last_name"
                                                horizontal
                                                :value="selected.usr_lastname"
                                                autocomplete="name"
                                                placeholder="Text"

                                        />


                                        <CInput
                                                description="Zone"
                                                label="Zone"
                                                horizontal
                                                :value="selected.zone_name"
                                                autocomplete="name"
                                                placeholder="Text"
                                                disabled

                                        />

                                        <CInput
                                                description="Building"
                                                label="Building"
                                                horizontal
                                                :value="selected.bul_name"
                                                autocomplete="name"
                                                placeholder="Text"
                                                disabled

                                        />

                                        <CInput
                                                description="Unit"
                                                label="Unit"
                                                horizontal
                                                :value="selected.uni_name"
                                                autocomplete="name"
                                                placeholder="Text"
                                                disabled

                                        />
                                    </CForm>
                                </CCardBody>
                                <CCardFooter>
                                    <CButton @click="updateUserProfile" style="left: auto" color="success"> Update
                                    </CButton>
                                </CCardFooter>
                            </CCard>
                        </CTab>
                        <CTab title="Wlan Info" v-if="selected">
                            <CCard>
                                <CCardHeader>

                                    Edit User's WLAN

                                </CCardHeader>

                                <CCardBody>
                                    <CForm>
                                        <CInput
                                                label="Network Name"
                                                id="wlan_name"
                                                :value.sync="selected.wlan_ssid"

                                        />

                                        <CInput
                                                label="Network Passphrase"
                                                id="wlan_passphrase"
                                                :value.sync="selected.wlan_passphrase"

                                        />

                                        <CInput
                                                label="Vlan ID"
                                                :value.sync="selected.rvlan_vlanid"

                                        />


                                    </CForm>
                                </CCardBody>
                                <CCardFooter>
                                    <CButton @click="updateWlanNetowrk" style="left: auto" color="success"> Update
                                    </CButton>
                                </CCardFooter>

                            </CCard>
                            <br>
                        </CTab>

                        <CTab title="Broadcast Matrix" v-if="bcm_data">

                            <br>
                            <CListGroup v-for="ap in bcm_data">
                                <CListGroupItem> AP Mac: {{ap.apMac}}</CListGroupItem>
                                <CListGroupItem> AP Name: {{ap.deviceName}}</CListGroupItem>
                                <CListGroupItem> Description: {{ap.description}}</CListGroupItem>
                                <CListGroupItem> IP Address: {{ap.ip}}</CListGroupItem>
                                <CListGroupItem> AP Model: {{ap.model}}</CListGroupItem>
                                <CListGroupItem> WG24Id : {{ap.wlanGroup24Id}}</CListGroupItem>
                                <CListGroupItem> WG50Id : {{ap.wlanGroup50Id}}</CListGroupItem>
                                <CListGroupItem> Status: {{ap.status}}</CListGroupItem>
                            </CListGroup>
                            <br>
                        </CTab>

                        <CTab title="General AP Info" v-if="ap_data">

                            <br>
                            <CListGroup>
                                <CListGroupItem> AP Mac: {{ap_data.apMac}}</CListGroupItem>
                                <CListGroupItem> AP Name: {{ap_data.deviceName}}</CListGroupItem>
                                <CListGroupItem> Description: {{ap_data.description}}</CListGroupItem>
                                <CListGroupItem> IP Address: {{ap_data.ip}}</CListGroupItem>
                                <CListGroupItem> AP Model: {{ap_data.model}}</CListGroupItem>

                                <CListGroupItem> Status: {{ap_data.status}}</CListGroupItem>
                            </CListGroup>
                        </CTab>
                        <CTab v-if="ap_data" title="WlanGroups Info">
                            <br>
                            <CListGroup v-if="ap_data">
                                <CListGroupItem> WlanGroup24Name: {{ap_data.wlanGroup24Name}}</CListGroupItem>
                                <CListGroupItem> WlanGroup24Id: {{ap_data.wlanGroup24Id}}</CListGroupItem>
                                <CListGroupItem> WlanGroup50Id: {{ap_data.wlanGroup50Id}}</CListGroupItem>
                                <CListGroupItem> WlanGroup50Name: {{ap_data.wlanGroup50Name}}</CListGroupItem>
                            </CListGroup>
                        </CTab>
                        <CTab v-if="ap_data" title="Channel Info">
                            <br>
                            <CListGroup v-if="ap_data">
                                <CListGroupItem> Channel24G: {{ap_data.channel24G}}</CListGroupItem>
                                <CListGroupItem> Channel50G: {{ap_data.channel5G}}</CListGroupItem>
                                <CListGroupItem> retry24G: {{ap_data.retry24G}}</CListGroupItem>
                                <CListGroupItem> retry50G: {{ap_data.retry5G}}</CListGroupItem>
                            </CListGroup>
                        </CTab>
                        <CTab v-if="ap_data" title="Clients">
                            <br>
                            <CCard>
                                <CCardHeader>
                                    Clients
                                    <div v-if="clientsync" class="lds-ripple">
                                        <div></div>
                                        <div></div>
                                    </div>
                                    <CButton v-if="!clientsync && ap_data" type="submit" size="sm"
                                             @click="getClients(selected)"
                                             color="info">
                                        🔄 with controller
                                    </CButton>
                                </CCardHeader>
                                <CCardBody>
                                    <CDataTable
                                            hover
                                            striped
                                            sorter
                                            columnFilter
                                            itemsPerPageSelect
                                            responsive
                                            :items="clients"
                                            :fields="fields"
                                            :items-per-page="50"
                                            :pagination="{ doubleArrows: true, align: 'center'}"


                                    >
                                    </CDataTable>
                                </CCardBody>
                            </CCard>
                            <CRow>

                                <CCol sm="6">
                                    <CCard v-if="clients">
                                        <CCardHeader>
                                            Client OS Type
                                        </CCardHeader>
                                        <CCardBody>

                                            <CChartDoughnut
                                                    :datasets="graph_data"
                                                    :labels="operating_systems"
                                            />

                                        </CCardBody>

                                    </CCard>
                                </CCol>
                                <CCol sm="6">
                                    <CCard v-if="clients">
                                        <CCardHeader>
                                            Clients by Traffic usage
                                        </CCardHeader>
                                        <CCardBody>

                                            <CChartBar
                                                    :datasets="host_traffic"
                                                    :labels="hostnames"
                                            />

                                        </CCardBody>

                                    </CCard>
                                </CCol>
                            </CRow>
                        </CTab>

                        <CTab v-if="ap_data" title="RX/TX Info">
                            <CListGroup v-if="ap_data">
                                <CListGroupItem> Overall Tx : {{ap_data.tx}}</CListGroupItem>
                                <CListGroupItem> Overall Rx: {{ap_data.rx}}</CListGroupItem>
                                <CListGroupItem> Overall 2.4 GHz Tx: {{ap_data.tx24G}}</CListGroupItem>
                                <CListGroupItem> Overall 2.4 GHz Rx: {{ap_data.rx24G}}</CListGroupItem>
                                <CListGroupItem> Overall 5 GHz Tx: {{ap_data.tx5G}}</CListGroupItem>
                                <CListGroupItem> Overall 5 GHz Rx: {{ap_data.rx5G}}</CListGroupItem>
                                <br>
                            </CListGroup>
                        </CTab>

                        <CTab v-if="ap_data && ap_members" @click="getClients(selected)" title="Hosted Networks">

                        </CTab>
                    </CTabs>
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
    import axios from "axios";
    import Forms from "../base/Forms";
    import {CChartBar, CChartDoughnut} from '@coreui/vue-chartjs'
    import Badges from "../notifications/Badges";
    import {api} from "../../api";

    export default {
        name: 'UserDetails',
        components: {Badges, Forms, CChartDoughnut, CChartBar},
        beforeRouteEnter(to, from, next) {
            next(vm => {
                vm.usersOpened = from.fullPath.includes('users')
            })
        },
        data() {
            return {

                search_criteria: "",
                client_count: {},
                timer: undefined,
                founded_users: [],
                clients: [],
                operating_systems: [],
                wlan_data: null,
                ap_members: {
                    'members_24': [],
                    'members_50': []
                },
                email: {
                    subject: "",
                    content: "",
                },
                userUpdateFlag: false,
                wlansync: false,
                apsync: false,
                clientsync: false,
                emailModal: false,
                hostnames: [],
                host_traffic: [
                    {
                        label: 'Traffic used',
                        backgroundColor: '#798af8',
                        data: []
                    }
                ],
                graph_data: [],

                fields: [
                    {key: 'clientMac'},
                    {key: 'hostname'},
                    {key: 'deviceType'},
                    {key: 'osType'},
                    {key: 'ipAddress'},
                    {key: 'txBytes'},
                    {key: 'rxBytes'},
                    {key: 'uplink'},
                    {key: 'downlink'},
                    {key: 'traffic'},
                    {key: 'radioType'}
                ],
                selected: null,
                pull_data: true,
                bcm_data: null,
                ap_data: null,
                queries: ['usr_id', 'usr_firstname', 'usr_lastname', 'usr_email',
                    'usr_phonenumber',
                    'wlan_ssid', 'uni_name', 'rap_mac', 'rvlan_vlanid'],
            }
        },
        methods: {
            userLookup() {
                clearTimeout(this.timer)
                if (this.search_criteria == "") {
                    return
                }
                this.timer = setTimeout(() => {
                    api.search_user(this.search_criteria).then(
                        (res) => {
                            this.founded_users = res.data;
                        }
                    ).catch(
                        (error) => {
                            console.log(error);
                        }
                    )
                }, 500)
                // axios.get(this.$baseURL + '/users/db/deep_search?search=' + this.search_criteria)
                //     .then((res) => {
                //         this.founded_users = res.data;
                //     }).catch(
                //     (error) => {
                //         console.log(error);
                //     }
                // )
            },
            closeModal(status, evt, accept) {
                if (accept) {
                    this.email['email_receiver'] = this.selected.usr_email;
                    axios.post(this.$baseURL + '/email/send_email',
                        this.email).then(res => {
                        this.$toast.success(res);
                    }).catch((error) => {
                        this.$toast.error(error);
                    })
                } else {
                    this.email.subject = "";
                    this.email.content = "";
                }
            },
            getById(id) {
                return document.getElementById(id)._value
            },
            updateUserProfile() {

                this.userUpdateFlag = true;
                this.userUpdateFlag = true;
                this.selected.usr_email = this.getById('email_address');
                this.selected.usr_phonenumber = this.getById('phone_number');
                this.selected.usr_firstname = this.getById('first_name');
                this.selected.usr_lastname = this.getById('last_name');

                axios.put(this.$baseURL + '/users/?usr_id=' + this.selected.usr_id, this.selected)
                    .then((res) => {
                        this.userUpdateFlag = !this.userUpdateFlag;
                        console.log(this.userUpdateFlag);
                        this.selected = res.data;
                        this.$toast.success("User updated successfully.");

                    }).catch((error) => {
                    this.$toast.error(error.response.data.detail);
                    this.userUpdateFlag = !this.userUpdateFlag;
                })

            },
            updateWlanNetowrk() {
                this.wlanUpdateFlag = true;
                this.selected.wlan_ssid = this.getById('wlan_name');
                this.selected.wlan_passphrase = this.getById('wlan_passphrase');

                axios.put(this.$baseURL + '/users/wlan/?usr_id=' + this.selected.usr_id, this.selected)
                    .then((res) => {
                        this.wlanUpdateFlag = false;
                        this.user = res.data;
                        this.$toast.success("Wlan updated successfully");


                    }).catch((error) => {
                    this.wlanUpdateFlag = false;
                    this.$toast.error(error.response.data.detail);

                })
            },
            selectUser(sel) {
                this.$toast.info("Syncing data with controller..")
                this.clients = [];
                this.selected = sel;
                var new_list = [];
                this.founded_users.forEach(
                    e => {
                        if (e.usr_email == sel.usr_email) {
                            new_list.push(e)
                        }
                    }
                )
                this.founded_users = new_list;
                this.graph_data = [];
                this.ap_members = {
                    'members_24': [],
                    'members_50': []
                }
                this.getBCM(sel);
                // this.getWlan(sel);
                this.apDetails(sel);
                this.getClients(sel);
                // this.apMembers(sel);

            },

            getBCM(sel) {

                api.get_bcm(this.selected.usr_email).then(
                    (res) => {
                        this.bcm_data = res.data;
                    }
                ).catch(
                    (error) => {
                        this.$toast.error(error);
                    }
                )
            },

            apDetails(sel) {
                this.selected = sel;
                this.apsync = true;
                this.ap_data = null;
                if (!sel.rap_mac) {
                    this.$toast.error("User's unit doesn't have AP installed. Please refer to Broadcast Matrix.");
                    return
                }
                api.get_ap(sel.rap_mac, sel.rctl_id).then(
                    res => {
                        this.ap_data = res.data;
                        this.$toast.success("Successfully synced AP with controller");
                        this.apsync = false;
                    }
                ).catch((error) => {
                    this.$toast.error("Failed to sync AP due to " + error);
                    console.log(error);
                    this.apsync = false;
                })

            },
            getClients(sel) {
                this.selected = sel;

                this.clientsync = true;
                api.get_clients(sel.rctl_id, sel.usr_email, sel.wlan_ruckus_id).then(
                    (res) => {
                        this.clients = res.data;
                        this.$toast.success("Successfully synced AP with controller");
                        this.operating_systems = [];
                        this.hostnames = [];
                        this.client_count = [];
                        var traffic = []
                        res.data.forEach(e => {
                                if (e.osType !== 'undefined') {

                                    this.operating_systems.push(e.osType);

                                    this.hostnames.push(e.hostname);
                                    traffic.push(e.traffic * 10e-9);
                                    if (e.osType in this.client_count) {
                                        this.client_count[e.osType] = this.client_count[e.osType] + 1;
                                    } else {

                                        this.client_count[e.osType] = 1;
                                    }
                                }
                            }
                        ).catch((error) => {
                            this.clientsync = false;
                            this.$toast.error("Failed to sync AP due to " + error);
                            console.log(error);
                        })
                    })
            },
            apMembers(sel) {
                axios.get(`${this.$baseURL}/ruckus/ap/members?zone_id=${sel.zone_rid}&wg_24_id=${this.ap_data.wlanGroup24Id}&wg_50_id=${this.ap_data.wlanGroup50Id}&rctl_id=${sel.rctl_id}`)
                    .then((res) => {
                        this.ap_members = res.data;
                        this.$toast.success("Successfully fetched member WLANs")
                    }).catch((error) => {
                    this.$toast.error(error)
                })
            }
            ,
            getWlan(sel) {
                this.wlansync = true;
                axios.get(`${this.$baseURL}/ruckus/wl/details?wl_id=${sel.rwlan_wlanid}&rctl_id=${sel.rctl_id}&zone_rid=${sel.zone_rid}`)
                    .then((res) => {
                        this.wlan_data = res.data;
                        this.wlansync = false
                        this.$toast.success("Successfully fetched member WLANs")
                    }).catch((error) => {
                    this.$toast.error(error);
                    this.wlansync = false;
                })
            }
            ,
            formatBytes(bytes) {
                var marker = 1024; // Change to 1000 if required
                var decimal = 3; // Change as required
                var kiloBytes = marker; // One Kilobyte is 1024 bytes
                var megaBytes = marker * marker; // One MB is 1024 KB
                var gigaBytes = marker * marker * marker; // One GB is 1024 MB

                // return bytes if less than a KB
                if (bytes < kiloBytes) return bytes + " Bytes";
                // return KB if less than a MB
                else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
                // return MB if less than a GB
                else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
                // return GB if less than a TB
                else return (bytes / gigaBytes).toFixed(decimal) + " GB";
            }
            ,
        },
        mounted() {
            this.validateSession();
        }
        ,
        computed: {
            defaultDatasets() {
                return [
                    {
                        label: 'GitHub Commits',
                        backgroundColor: '#f87979',
                        data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20, 12, 11]
                    }
                ]
            }
        }
    }
</script>


<style scoped>
    .lds-facebook {
        display: inline-block;
        position: relative;
        width: 80px;
        height: 80px;
    }

    .lds-facebook div {
        display: inline-block;
        position: absolute;
        left: 8px;
        width: 16px;
        background: #4673e7;
        animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
    }

    .lds-facebook div:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
    }

    .lds-facebook div:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
    }

    .lds-facebook div:nth-child(3) {
        left: 56px;
        animation-delay: 0;
    }

    @keyframes lds-facebook {
        0% {
            top: 8px;
            height: 64px;
        }
        50%, 100% {
            top: 24px;
            height: 32px;
        }
    }

    .lds-ripple {
        display: inline-block;
        position: relative;
        width: 10px;
        height: 10px;
    }

    .lds-ripple div {
        position: absolute;
        border: 4px solid #4673e7;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

    .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
    }

    @keyframes lds-ripple {
        0% {
            top: 11px;
            left: 16px;
            width: 0;
            height: 0;
            opacity: 1;
        }
        100% {
            top: 4px;
            left: 0px;
            width: 12px;
            height: 12px;
            opacity: 0;
        }
    }


</style>